<template>
    <div>
        <Header></Header>
        <div class="container mx-auto py-4">
            <breadcrumb></breadcrumb>
            <div class="border border-gray-400 rounded-3xl mt-2 p-4">
                <div class="py-4 px-6 border border-gray-200 rounded-xl shadow-lg">
                    <div class="text-lg font-bold mb-4">Notifications2</div>
                    <div v-for="(notification, index) in $store.state.notifications" :key="notification.id_notifikasi">
                        <div class="text-primary font-semibold">{{ notification.judul }}</div>
                        <div class="text-xs text-gray-700 font-semibold">
                            {{ notification.waktu_buat }}
                        </div>
                        <div class="text-sm text-gray-700 font-medium mt-2">{{ notification.isi }}</div>
                        <hr v-if="index < $store.state.notifications.length - 1" class="my-2" />
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import Breadcrumb from '@/components/layout/Breadcrumb.vue';
    import Footer from '@/components/Footer';

    export default {
        name: 'Notifications',

        components: {
            Header,
            Breadcrumb,
            Footer
        },

        async created() {
            this.$store.commit('setBreadcrumbItems', [
                { text: 'Home', routeName: 'Home' },
                { text: 'Notifications', routeName: 'Notifications' }
            ]);
            
            try {
                const response = await this.$http.post(`${this.$apiTripweWeb}/notification/update`);
                if (response.data.status != 20000) {
                    throw response.data.message;
                }
            } catch (error) {
                this.$toasted.global.error(error);
            }
        }
    };
</script>
